import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import { Table, Tooltip, Modal, Popconfirm, Button, Descriptions, Row, Col, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { useHistory } from 'react-router';
import fileDownload from 'js-file-download';
import { CalendarOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import { dateSorter, filterDropdown, get, onFilter, sorter, dateFilterDropdown, onDateFilter } from '../utils';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import {
  clearOrdersInProductionPlanning,
  getOrderDispenseDetailsList,
  clearOrderDispenseDetailsList,
  setPurchaseOrder,
  getOrderShortageItemsList,
  clearOrderShortageItemsList,
  getPurchaseOrderList,
  clearPurchaseOrderList,
  getItemsToOrderList,
  clearItemsToOrderList,
  getOrdersInProductionPlanning,
} from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import { materialTypes } from '../constants';
import ItemsToOrderTable from '../Inventory/ItemsToOrderTable';
import DispatchDateModal from './DispatchDateModal';

const ProductionPlanningList = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const printRef = useRef();

  const auth = useSelector(state => state.auth);
  const isInProductionPlanningOrders = useSelector(state => state.isInProductionPlanningOrders);
  const orderDispenseDetailsList = useSelector(state => state.orderDispenseDetailsList);
  const orderShortageItemsList = useSelector(state => state.orderShortageItemsList);
  const purchaseOrderList = useSelector(state => state.purchaseOrderList);

  const permissions = get(auth, ['permissions'], []);
  const orderRawMaterials = get(orderDispenseDetailsList, ['orderRawMaterials'], []);
  const orderOtherMaterials = get(orderDispenseDetailsList, ['orderOtherMaterials'], []);
  const weights = get(orderDispenseDetailsList, ['weights'], []);
  const formulationInventory = get(orderDispenseDetailsList, ['formulationInventory'], {});
  const isReadyToManufacturing = get(orderDispenseDetailsList, ['isReadyToManufacturing'], null);

  const [orderId, setOrderId] = useState(null);
  const [materialIds, setMaterialIds] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [order, setOrder] = useState({});

  const role = get(auth, ['user', 'role'], null);

  const orderData = useMemo(
    () => isInProductionPlanningOrders.find(el => el.id === orderId),
    [orderId, isInProductionPlanningOrders]
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '',
  });

  useEffect(() => {
    dispatch(getOrdersInProductionPlanning());
    return () => {
      dispatch(clearOrdersInProductionPlanning());
      dispatch(clearOrderShortageItemsList());
      dispatch(clearPurchaseOrderList());
    };
  }, []);

  useEffect(() => {
    if (orderId && modalType === 'DispenseDetails') {
      dispatch(getOrderDispenseDetailsList(orderId));
    }
    if (orderId && modalType === 'ShortageItemsList') {
      dispatch(getOrderShortageItemsList(orderId));
    }
    if (materialIds.length && modalType === 'CommonItemsList') {
      dispatch(getItemsToOrderList({ ids: materialIds, consignor: orderData.consignor }));
    }
    if (orderId && modalType === 'ViewPOList') {
      dispatch(getPurchaseOrderList({ orderId }));
    }
  }, [orderId, modalType, orderData]);

  const showModal = (id, type, _materialIds = null) => {
    setOrderId(id);
    setModalType(type);
    setMaterialIds(_materialIds || []);
  };

  const hideModal = () => {
    setOrderId(null);
    setModalType(null);
    // dispatch(clearOrdersInProductionPlanning());
    // dispatch(getOrdersInProductionPlanning());
    dispatch(clearOrderDispenseDetailsList());
    dispatch(clearOrderShortageItemsList());
    dispatch(clearPurchaseOrderList());
    dispatch(clearItemsToOrderList());
  };

  const onUndoProductionPlanningApproval = async id => {
    try {
      await Api.put(`/orders/${id}/undomovetoproductionplanning`);
      successNotification('Order moved back.');
      dispatch(clearOrdersInProductionPlanning());
      dispatch(getOrdersInProductionPlanning());
    } catch (err) {
      errorNotification(err);
    }
  };

  const exportPlanningList = async () => {
    try {
      const { data } = await Api.getFile(`/orders/isinproductionplanning/export`);
      fileDownload(data, `final-planning-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const onDispense = async materialId => {
    try {
      await Api.post(`/orders/dispense`, { orderId, materialId });
      successNotification(`Material dispensed`);
      dispatch(getOrderDispenseDetailsList(orderId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onManufacturingStart = async () => {
    try {
      await Api.put(`/orders/${orderId}/startmanufacturing`);
      successNotification(`Manufacturing started`);
      hideModal();
      dispatch(getOrdersInProductionPlanning());
    } catch (err) {
      errorNotification(err);
    }
  };

  const isInManufacturingFlag = async id => {
    try {
      await Api.put(`/orders/${id}/isinmanufacturingflag`);
      successNotification(`Manufacturing status updated`);
      hideModal();
      dispatch(getOrdersInProductionPlanning());
    } catch (err) {
      errorNotification(err);
    }
  };

  const tooltipShow = (text, record) => {
    let color = 'Black';
    if (record) {
      if (record.isInManufacturingFlag === true) {
        color = 'Blue';
      }
    }

    return (
      <Tooltip placement="topLeft" title={text}>
        <span style={{ color }}>{text}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Section Seq. #',
      dataIndex: 'seqno',
      width: 150,
      fixed: 'left',
      render: (text, record) => `${moment(record.estDispatchDate).format('DD MMM')}/${record.section}/${record.seqno}`,
    },
    {
      title: 'Order #',
      dataIndex: 'id',
      // sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 95,
      fixed: 'left',
    },
    {
      title: 'Finished Good Name',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
      render: (text, record) => tooltipShow(text, record),
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      ellipsis: { showTitle: false },
      render: (text, record) => tooltipShow(text, record),
      width: 120,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
      render: (text, record) => tooltipShow(text, record),
      width: 100,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: 90,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      width: 90,
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      sorter: sorter('mrp'),
      filterDropdown,
      onFilter: onFilter('mrp'),
      width: 90,
    },
    {
      title: 'Nature',
      dataIndex: 'nature',
      sorter: sorter('nature'),
      filterDropdown,
      onFilter: onFilter('nature'),
      render: nature => startCase(nature),
      width: 100,
    },
    {
      title: 'Authorized',
      dataIndex: 'authorizedAt',
      sorter: dateSorter('authorizedAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('authorizedAt'),
      render: date => (date ? moment(date).format('DD MMM YY') : null),
      width: 120,
    },
    {
      title: 'Est. Dispatch',
      dataIndex: 'estDispatchDate',
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('estDispatchDate'),
      dateFilterDropdown: dateFilterDropdown('estDispatchDate'),
      width: 120,
      render: (_text, record) => (
        <>
          {record.estDispatchDate ? <span>{moment(record.estDispatchDate).format('DD MMM YY hh:mm A')} | </span> : null}
          {!record.isInManufacturingFlag ? (
            <CalendarOutlined onClick={() => showModal(record.id, 'espDistachDate')} />
          ) : null}
        </>
      ),
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: (text, record) => tooltipShow(text, record),
    },
    {
      title: 'In Manufacturing',
      dataIndex: 'isInManufacturingFlag',
      width: 150,
      sorter: sorter('isInManufacturingFlag'),
      filterDropdown,
      onFilter: onFilter('isInManufacturingFlag'),
      render: (_text, record) => (
        <>
          {startCase(record.isInManufacturingFlag)} &nbsp;&nbsp; | &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Manufacturing Status">
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => isInManufacturingFlag(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <EditOutlined />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  columns.push({
    title: 'Undo Production Planning',
    key: 'undoProductionPlanning',
    width: 90,
    render: (_text, record) =>
      !record.isDispensed && (
        <Tooltip placement="bottom" title="Undo">
          <Popconfirm
            title="Are you sure you want to undo?"
            onConfirm={() => onUndoProductionPlanningApproval(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <UndoOutlined />
          </Popconfirm>
        </Tooltip>
      ),
  });

  const renderShortageItemsColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      return (
        <Tooltip placement="bottom" title="Shortage Items List">
          <a
            onClick={() => showModal(record.id, 'ShortageItemsList')}
            onKeyDown={() => showModal(record.id, 'ShortageItemsList')}
            role="link"
            tabIndex={0}
          >
            View
          </a>
        </Tooltip>
      );
    }
    return null;
  };

  const renderCommonItemsColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      return (
        <Tooltip placement="bottom" title="Common Items List">
          <a
            onClick={() => showModal(record.id, 'CommonItemsList', record.conflictMaterials)}
            onKeyDown={() => showModal(record.id, 'CommonItemsList', record.conflictMaterials)}
            role="link"
            tabIndex={0}
          >
            View
          </a>
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('shortage_item_list')) {
    columns.push(
      {
        title: 'Shortage',
        width: 80,
        render: renderShortageItemsColumn,
        fixed: 'right',
      },
      {
        title: 'Common',
        width: 80,
        render: renderCommonItemsColumn,
        fixed: 'right',
      }
    );
  }

  const renderDispenseDetailsColumn = record => {
    if (permissions.includes('dispense_details') && record.isInProductionPlanning && record.isInManufacturingFlag) {
      const onClick = () => {
        setOrder({
          id: record.id,
          formulation: record.formulation,
          packSize: record.formulationPackSize,
          quantity: record.quantity,
          unit: record.formulation.unit,
          estDispatchDate: record.estDispatchDate,
          remarks: record.remarks,
          internalRemarks: record.internalRemarks,
          formulationRemark: record.formulationRemark,
          section: record.section,
          seqno: record.seqno,
        });
        showModal(record.id, 'DispenseDetails');
      };

      if (role !== 'Regulatory') {
        return (
          <Tooltip placement="bottom" title="Dispense Details">
            <a onClick={onClick} onKeyPress={onClick} role="link" tabIndex={0}>
              View
            </a>
          </Tooltip>
        );
      }
      if (role === 'Regulatory' && record.consignor !== 'Plus Formulations (Head Office)') {
        return (
          <Tooltip placement="bottom" title="Dispense Details">
            <a onClick={onClick} onKeyPress={onClick} role="link" tabIndex={0}>
              View
            </a>
          </Tooltip>
        );
      }
      return null;
    }
    return null;
  };

  if (permissions.includes('dispense_details')) {
    columns.push({
      title: 'Dispense',
      width: 80,
      render: renderDispenseDetailsColumn,
      fixed: 'right',
    });
  }

  const dispenseDetailsTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
    },
  ];

  if (role === 'Owner' || role === 'Regulatory') {
    dispenseDetailsTableColumns.push({
      title: 'Quantity',
      dataIndex: 'orderUsage',
      align: 'right',
    });
  }

  // dispenseDetailsTableColumns.push(
  //   {
  //     title: 'Dispensed By',
  //     dataIndex: 'createdBy',
  //   },
  //   {
  //     title: 'Dispensed On',
  //     dataIndex: 'createdAt',
  //     render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
  //   }
  // );

  const renderTableDispenseColumn = record => {
    if (record.isDispensed) {
      return 'Dispensed';
    }
    return (
      <Tooltip placement="bottom" title="Dispense">
        <Popconfirm
          title="Sure want to dispense material?"
          onConfirm={() => onDispense(record.materialId)}
          okText="Confirm"
          cancelText="Cancel"
        >
          <a>Dispense</a>
        </Popconfirm>
      </Tooltip>
    );
  };

  dispenseDetailsTableColumns.push({
    title: 'Dispense',
    render: renderTableDispenseColumn,
  });

  const setPurchaseOrderValues = (materialId, toOrder) => {
    dispatch(setPurchaseOrder({ orderId, materialId, quantity: toOrder }));
    history.push('/purchaseorder/new');
  };

  const renderCreatePurchaseOrderColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      if (record.toOrder > 0) {
        return (
          <Tooltip placement="bottom" title="Create Purchase Order">
            <a
              role="link"
              tabIndex={0}
              onClick={() => setPurchaseOrderValues(record.materialId, record.toOrder)}
              onKeyPress={() => setPurchaseOrderValues(record.materialId, record.toOrder)}
            >
              Create
            </a>
          </Tooltip>
        );
      }
      return null;
    }
    return null;
  };

  const rowTextInColor = (arg, record) => {
    let color;
    if (record.pendingPOs === null) {
      color = 'Red';
    } else {
      color = '#096dd9';
    }

    return <span style={{ color }}>{materialTypes.includes(`${arg}`) ? startCase(arg) : arg}</span>;
  };

  const shortageItemTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: rowTextInColor,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: rowTextInColor,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      render: rowTextInColor,
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Qty Req. for this Order',
      dataIndex: 'usagePerOrder',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Qty Req. for all Orders',
      dataIndex: 'totalUsage',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Pending PO Qty',
      dataIndex: 'pendingPurchaseOrderQuantity',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Req. contributed by',
      dataIndex: 'usedByFormulations',
      // eslint-disable-next-line react/display-name
      render: (_data, record) => {
        let color;
        if (record.pendingPOs === null) {
          color = 'Red';
        } else {
          color = '#096dd9';
        }

        return <span style={{ color }}>{(record.usedByFormulations || []).join(', ')}</span>;
      },
    },
    {
      title: 'To Order',
      dataIndex: 'toOrder',
      // eslint-disable-next-line react/display-name
      render: (_data, record) => {
        let color;
        if (record.pendingPOs === null) {
          color = 'Red';
        } else {
          color = '#096dd9';
        }

        return (
          <span style={{ color }}>
            {(+record.totalUsage - +record.stock - +record.pendingPurchaseOrderQuantity).toFixed(2)}
          </span>
        );
      },
      align: 'right',
    },
  ];

  if (permissions.includes('new_purchase_order')) {
    shortageItemTableColumns.push({
      title: 'Create PO',
      render: renderCreatePurchaseOrderColumn,
    });
  }

  // const renderViewPOListColumn = record => {
  //   if (permissions.includes('view_purchase_orders_of_order')) {
  //     return (
  //       <Tooltip placement="bottom" title="View PO">
  //         <a
  //           onClick={() => showModal(record.id, 'ViewPOList')}
  //           onKeyPress={() => showModal(record.id, 'ViewPOList')}
  //           role="link"
  //           tabIndex={0}
  //         >
  //           View
  //         </a>
  //       </Tooltip>
  //     );
  //   }
  //   return null;
  // };

  // if (permissions.includes('view_purchase_orders_of_order')) {
  //   columns.push({
  //     title: 'View PO',
  //     width: 60,
  //     render: renderViewPOListColumn,
  //     fixed: 'right',
  //   });
  // }

  const ViewPOListColumns = [
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
    },
    {
      title: 'PO No.',
      dataIndex: 'id',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      ellipsis: { showTitle: false },
      render: (text, record) => tooltipShow(text, record),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      align: 'right',
      render: rate => Number(rate).toLocaleString('en-IN'),
    },
    {
      title: 'Qty Received',
      dataIndex: 'received',
      align: 'right',
    },
    {
      title: 'Lead Time',
      dataIndex: 'leadTime',
      align: 'right',
    },
    {
      title: 'Expected On',
      dataIndex: 'expectedBy',
      render: (_text, record) =>
        record.createdAt && record.leadTime
          ? moment(record.createdAt).add(record.leadTime, 'days').format('DD MMM YYYY')
          : null,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      ellipsis: { showTitle: false },
      render: (text, record) => tooltipShow(text, record),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Final Planning">
          <Button type="default" onClick={exportPlanningList}>
            Export
          </Button>
        </Heading>
        <br />
        <Row gutter={[8, 8]}>
          <Col>
            <Badge color="Blue" text="In Manufacturing" />
          </Col>
          <Col>
            <Badge color="Black" text="Final Planning" />
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Records` }}
          dataSource={isInProductionPlanningOrders}
          size="small"
          scroll={{ x: 2000, y: window.innerHeight - 150 }}
        />
        <Modal
          onOk={hideModal}
          open={modalType === 'DispenseDetails'}
          style={{ top: 10 }}
          maskClosable={false}
          closable={false}
          title="Dispense Details."
          width={900}
          okText="Close"
          cancelText="Print"
          okButtonProps={{ className: classes.button }}
          cancelButtonProps={{ className: classes.button }}
          onCancel={handlePrint}
        >
          <div ref={printRef}>
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="Order #">
                <span className={classes.detailColor}>{order.id}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Formulation">
                <span className={classes.detailColor}>{order.formulation}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Batch Size">
                <span className={classes.detailColor}>
                  {(+order.packSize * +order.quantity).toFixed(2)} {order.unit}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                <span className={classes.detailColor}>{order.quantity}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Order Remark">
                <span className={classes.detailColor}>{order.remarks}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Internal Order Remark">
                <span className={classes.detailColor}>{order.internalRemarks}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Formulation Remark">
                <span className={classes.detailColor}>{order.formulationRemark}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Estimated Dispatch Date">
                <span className={classes.detailColor}>
                  {order.estDispatchDate ? moment(order.estDispatchDate).format('DD MMM YY hh:mm:ss A') : null}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Remaining Inventory">
                <span className={classes.detailColorImp}>{formulationInventory?.stock}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Section Seq. #">
                <span className={classes.detailColor}>{`${moment(order.estDispatchDate).format('DD MMM')}/${
                  order.section
                }/${order.seqno}`}</span>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Status">
                <span className={classes.detailColor}>{order.status}</span>
              </Descriptions.Item> */}
            </Descriptions>
            <br />
            {permissions.includes('raw_materials_dispense') ? (
              <Table
                columns={dispenseDetailsTableColumns}
                bordered
                rowKey={record => record.materialId}
                dataSource={orderRawMaterials}
                size="small"
                pagination={false}
                scroll={{ x: 425 }}
              />
            ) : null}
            <br />
            {permissions.includes('raw_materials_dispense') ? (
              <Table
                columns={[
                  {
                    title: 'Unit',
                    dataIndex: 'unit',
                  },
                  {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                  },
                ]}
                title={() => 'Raw Materials Weight Table'}
                bordered
                rowKey={record => record.units}
                dataSource={weights}
                size="small"
                pagination={false}
                scroll={{ x: 425 }}
              />
            ) : null}
            <br />

            {permissions.includes('other_materials_dispense') ? (
              <Table
                columns={dispenseDetailsTableColumns}
                bordered
                rowKey={record => record.materialId}
                dataSource={orderOtherMaterials}
                size="small"
                pagination={false}
                scroll={{ x: 425 }}
              />
            ) : null}
            <br />
            {permissions.includes('start_manufacturing') && isReadyToManufacturing ? (
              <Popconfirm
                title="Sure want to start manufacturing?"
                onConfirm={() => onManufacturingStart()}
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button className={`${classes.button}`}>Start Manufacturing</Button>
              </Popconfirm>
            ) : null}
          </div>
        </Modal>
        {modalType === 'ShortageItemsList' ? (
          <Modal
            open={modalType === 'ShortageItemsList'}
            onOk={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Shortage Items List."
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            width="90%"
            okButtonProps={{ className: classes.button }}
          >
            <Table
              columns={shortageItemTableColumns}
              bordered
              rowKey={record => record.materialId}
              dataSource={orderShortageItemsList}
              size="small"
              pagination={false}
              scroll={{ x: 425 }}
              expandable={{
                // eslint-disable-next-line react/display-name
                expandedRowRender: record => (
                  <div style={{ margin: '0 0 0 14px' }}>
                    <Table
                      columns={ViewPOListColumns}
                      bordered
                      rowKey={data => data.id}
                      dataSource={record.pendingPOs}
                      size="small"
                      pagination={false}
                      scroll={{ x: 425 }}
                    />
                  </div>
                ),
                rowExpandable: record => record.pendingPOs !== null,
              }}
            />
          </Modal>
        ) : null}
        {modalType === 'CommonItemsList' ? (
          <Modal
            open
            onOk={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Common Items List."
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            width="90%"
            okButtonProps={{ className: classes.button }}
          >
            <ItemsToOrderTable />
          </Modal>
        ) : null}
        <Modal
          open={modalType === 'ViewPOList'}
          onOk={hideModal}
          centered
          maskClosable={false}
          closable={false}
          title="Purchase Orders."
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          width="1300px"
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={ViewPOListColumns}
            bordered
            rowKey={record => record.id}
            dataSource={purchaseOrderList || []}
            size="small"
            pagination={false}
            scroll={{ x: 425 }}
          />
        </Modal>
        {orderData && orderId && modalType === 'espDistachDate' && (
          <DispatchDateModal recordId={orderId} order={orderData} hideModal={hideModal} showTimeFormat />
        )}
      </>
    </Structure>
  );
};

ProductionPlanningList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductionPlanningList);
