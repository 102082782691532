import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Table, Tooltip } from 'antd';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import {
  getOrderReport,
  clearOrderReport,
  getPurchaseOrderReport,
  clearPurchaseOrderReport,
  getPurchaseReport,
  clearPurchaseReport,
  getSaleReport,
  clearSaleReport,
  getFormulationReport,
  clearFormulationReport,
  getMaterialReport,
  clearMaterialReport,
  getCustomersInventoryTotalReport,
  clearCustomersInventoryTotalReport,
  clearPaymentCategoryReport,
  getPaymentCategoryReport,
  getOrderThroughputBrands,
  clearOrderThroughputBrands,
} from '../Actions';
import { get, onFilter, sorter, filterDropdown } from '../utils';
import Api from '../Api';
import { errorNotification, infoNotification, successNotification } from '../Snackbar';

const Dashboard = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const authpermissions = get(auth, ['permissions'], []);

  const orderReport = useSelector(state => state.orderReport);
  const purchaseOrderReport = useSelector(state => state.purchaseOrderReport);
  const purchaseReport = useSelector(state => state.purchaseReport);
  const saleReport = useSelector(state => state.saleReport);
  const formulationReport = useSelector(state => state.formulationReport);
  const materialReport = useSelector(state => state.materialReport);
  const customerInventoryReport = useSelector(state => state.customerInventoryReport);
  const paymentCategoryReport = useSelector(state => state.paymentCategoryReport);
  const orderThroughputBrands = useSelector(state => state.orderThroughputBrands);

  const [inventoryTotal, setInventoryTotal] = useState(0);

  useEffect(() => {
    dispatch(getOrderReport());
    dispatch(getPurchaseOrderReport());
    dispatch(getPurchaseReport());
    dispatch(getSaleReport());
    dispatch(getFormulationReport());
    dispatch(getMaterialReport());
    dispatch(getCustomersInventoryTotalReport());
    dispatch(getPaymentCategoryReport());
    dispatch(getOrderThroughputBrands());

    return () => {
      dispatch(clearOrderReport());
      dispatch(clearPurchaseOrderReport());
      dispatch(clearPurchaseReport());
      dispatch(clearSaleReport());
      dispatch(clearFormulationReport());
      dispatch(clearMaterialReport());
      dispatch(clearCustomersInventoryTotalReport());
      dispatch(clearPaymentCategoryReport());
      dispatch(clearOrderThroughputBrands());
    };
  }, []);

  useEffect(() => {
    const rawAmount = Number(get(materialReport, ['rawAmount'], 0));
    const packingAmount = Number(get(materialReport, ['packingAmount'], 0));
    const printedPackagingAmount = Number(get(materialReport, ['printedPackagingAmount'], 0));
    const consumableAmount = Number(get(materialReport, ['consumableAmount'], 0));

    setInventoryTotal(rawAmount + packingAmount + printedPackagingAmount + consumableAmount);
  }, [materialReport]);

  const exportCustomerInventoryData = async () => {
    try {
      infoNotification('File downloading ...');

      const { data } = await Api.getFile(`/material-inventory/reports/customers/export`);

      fileDownload(data, `customer-inventory.csv`);

      successNotification('File downloaded');
    } catch (error) {
      errorNotification(error);
    }
  };

  const exportThroughputData = async () => {
    try {
      infoNotification('File downloading ...');

      const { data } = await Api.getFile(`/orders/reports/throughput/export`);

      fileDownload(data, `Throughput-Deficit-Brands.xlsx`);

      successNotification('File downloaded');
    } catch (error) {
      errorNotification(error);
    }
  };

  const paymentCategoryTableColumns = [
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Number of Active Customer in Category',
      dataIndex: 'customers',
    },
    {
      title: 'Number of Un-Authorised Order',
      dataIndex: 'unAuthorizedOrders',
    },
    {
      title: 'Number of Authorised Order',
      dataIndex: 'authorizedOrders',
    },
    {
      title: 'Authorised Order Amount',
      dataIndex: 'authorizedOrdersAmount',
      render: text => Number(text).toLocaleString('en-IN'),
    },
    {
      title: 'Throughput Deficit ',
      dataIndex: 'throughputDeficitAmount',
      render: text => Number(text).toLocaleString('en-IN'),
    },
  ];

  const throughputDeficitFormulationsTableColumns = [
    {
      title: 'Formulation',
      dataIndex: 'name',
      render: (_name, record) => record,
    },
  ];

  const sectionWiseOrdersTableColumns = [
    {
      title: 'Section',
      dataIndex: 'section',
    },
    {
      title: 'Total Orders',
      dataIndex: 'count',
      align: 'right',
    },
  ];

  const customersInventoryTotalTableColumns = [
    {
      title: 'Customer',
      dataIndex: 'name',
      filterDropdown,
      sorter: sorter('name'),
      onFilter: onFilter('name'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      filterDropdown,
      sorter: sorter('amount'),
      onFilter: onFilter('amount'),
      render: amount => Number(amount).toLocaleString('en-IN'),
      align: 'right',
    },
  ];

  const exportCustomerInventoryButton = () => {
    return (
      <>
        Customer Inventory ({customerInventoryReport.length})&nbsp;&nbsp;
        <Button type="default" onClick={exportCustomerInventoryData}>
          Export
        </Button>
      </>
    );
  };

  const exportThroughputButton = () => {
    return (
      <>
        <Tooltip title="Throughput Deficit Brands (30 days)">Throughput Deficit Brands (30 days)</Tooltip>
        &nbsp;&nbsp;
        <Button type="default" onClick={exportThroughputData}>
          Export
        </Button>
      </>
    );
  };

  return (
    <Structure>
      <>
        {authpermissions.includes('dashboard') ? (
          <>
            <Card size="default" title="Orders">
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={6}>
                      <Card size="small" title="Total Orders">
                        <span className={classes.dashboardNumberFont}>{get(orderReport, ['total'], '--')}</span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Card
                        size="small"
                        title={<Tooltip title="Throughput Deficit">Throughput Deficit (30 days)</Tooltip>}
                      >
                        <span className={classes.dashboardNumberFont}>
                          {Number(get(orderReport, ['throughputDeficit'], 0)).toLocaleString('en-IN')}
                        </span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Card
                        size="small"
                        title={
                          <Tooltip title="Full-Kitting Not Done & older then 25 Days">
                            Full-Kitting Not Done & older then 25 Days
                          </Tooltip>
                        }
                      >
                        <span className={classes.dashboardNumberFont}>{get(orderReport, ['fullKitting25'], '--')}</span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Card
                        size="small"
                        title={
                          <Tooltip title="Not in Production Planning & older then 25 Days">
                            Not in Production Planning & older then 25 Days
                          </Tooltip>
                        }
                      >
                        <span className={classes.dashboardNumberFont}>
                          {get(orderReport, ['notProductionPlanning25'], '--')}
                        </span>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={6}>
                      <Card size="small" title={<Tooltip title="Pending for Authorization">Not Authorized</Tooltip>}>
                        <span className={classes.dashboardNumberFont}>{get(orderReport, ['notAuthorized'], '--')}</span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Card
                        size="small"
                        title={<Tooltip title="Pending for Authorization">Not Authorized Amount</Tooltip>}
                      >
                        <span className={classes.dashboardNumberFont}>
                          {Number(get(orderReport, ['notAuthorizedValue'], 0)).toFixed(2)}
                        </span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Card size="small" title={<Tooltip title="Pending for Authorization">Authorized</Tooltip>}>
                        <span className={classes.dashboardNumberFont}>{get(orderReport, ['authorized'], '--')}</span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Card size="small" title={<Tooltip title="Pending for Authorization">Authorized Amount</Tooltip>}>
                        <span className={classes.dashboardNumberFont}>
                          {Number(get(orderReport, ['authorizedValue'], 0)).toFixed(2)}
                        </span>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={12}>
                      <Card size="small">
                        <Table
                          columns={paymentCategoryTableColumns}
                          bordered
                          rowKey={record => record}
                          pagination={{
                            defaultPageSize: 10,
                            position: ['bottomLeft'],
                          }}
                          dataSource={paymentCategoryReport || []}
                          size="small"
                          scroll={{ x: 425, y: 400 }}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="small" title="Throughput Deficit Brands (30 days)">
                        <Table
                          columns={throughputDeficitFormulationsTableColumns}
                          bordered
                          rowKey={record => record}
                          pagination={{
                            defaultPageSize: 10,
                            position: ['bottomLeft'],
                          }}
                          dataSource={get(orderReport, ['throughputDeficitFormulations'], [])}
                          size="small"
                          scroll={{ x: 425, y: 400 }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={24}>
                  <Card size="small" title={exportThroughputButton()}>
                    <Table
                      columns={[
                        {
                          title: 'Formulation',
                          dataIndex: 'formulation',
                          filterDropdown,
                          sorter: sorter('formulation'),
                          onFilter: onFilter('formulation'),
                        },
                        {
                          title: 'Generic',
                          dataIndex: 'generic',
                          filterDropdown,
                          sorter: sorter('generic'),
                          onFilter: onFilter('generic'),
                        },
                        {
                          title: 'Section',
                          dataIndex: 'section',
                          filterDropdown,
                          sorter: sorter('section'),
                          onFilter: onFilter('section'),
                        },
                        {
                          title: 'Category',
                          dataIndex: 'category',
                          filterDropdown,
                          sorter: sorter('category'),
                          onFilter: onFilter('category'),
                        },
                        {
                          title: 'Customer',
                          dataIndex: 'customer',
                          filterDropdown,
                          sorter: sorter('customer'),
                          onFilter: onFilter('customer'),
                        },
                      ]}
                      bordered
                      rowKey={record => record.formulationId}
                      pagination={{
                        defaultPageSize: 10,
                        position: ['bottomLeft'],
                        showTotal: total => `Total ${total} Records`,
                      }}
                      dataSource={orderThroughputBrands || []}
                      size="small"
                      scroll={{ x: 425, y: 400 }}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
            <Row gutter={[8, 8]}>
              <Col xs={24} lg={24}>
                <Card size="default" title="Production">
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={12}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24} lg={8}>
                          <Card size="small" title={<Tooltip title="Orders in Filling">Orders in Filling</Tooltip>}>
                            <span className={classes.dashboardNumberFont}>
                              {get(orderReport, ['underFilling'], '--')}
                            </span>
                          </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                          <Card size="small" title={<Tooltip title="Orders in Packing">Orders in Packing</Tooltip>}>
                            <span className={classes.dashboardNumberFont}>
                              {get(orderReport, ['underPacking'], '--')}
                            </span>
                          </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                          <Card
                            size="small"
                            title={<Tooltip title="Orders Ready to dispatch">Orders Ready to dispatch</Tooltip>}
                          >
                            <span className={classes.dashboardNumberFont}>
                              {get(orderReport, ['readyToDispatch'], '--')}
                            </span>
                          </Card>
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]}>
                        <Col xs={24} lg={8}>
                          <Card
                            size="small"
                            title={
                              <Tooltip title="Orders in Production Planning & older then 7 Days">
                                Orders in Production Planning & older then 7 Days
                              </Tooltip>
                            }
                          >
                            <span className={classes.dashboardNumberFont}>
                              {get(orderReport, ['productionPlanning7'], '--')}
                            </span>
                          </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                          <Card
                            size="small"
                            title={<Tooltip title="Orders in Manufacturing">Orders in Manufacturing</Tooltip>}
                          >
                            <span className={classes.dashboardNumberFont}>
                              {get(orderReport, ['underManufacturing'], '--')}
                            </span>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card
                        size="small"
                        title={
                          <Tooltip title="Orders in Production Planning - Section wise">
                            Orders in Production Planning - Section wise
                          </Tooltip>
                        }
                      >
                        <Table
                          columns={sectionWiseOrdersTableColumns}
                          bordered
                          rowKey={record => record.section}
                          pagination={{
                            defaultPageSize: 10,
                            position: ['bottomLeft'],
                          }}
                          dataSource={get(orderReport, ['productionPlanningOrdersBySections'], [])}
                          size="small"
                          scroll={{ x: 425, y: 400 }}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]} />
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24} lg={4}>
                <Card size="default" title="Formulations">
                  <span className={classes.dashboardNumberFont}>{get(formulationReport, ['formulations'], '--')}</span>
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card size="default" title="Purchase Order">
                  <Row gutter={[8, 8]}>
                    <Col xs={24} xl={12}>
                      <Card
                        size="small"
                        title={<Tooltip title="Orders on higher price then PO">Orders on higher price then PO</Tooltip>}
                      >
                        <span className={classes.dashboardNumberFont}>
                          {get(purchaseOrderReport, ['overPriced'], '--')}
                        </span>
                      </Card>
                    </Col>
                    <Col xs={24} xl={12}>
                      <Card
                        size="small"
                        title={<Tooltip title="Partial orders received">Partial orders received</Tooltip>}
                      >
                        <span className={classes.dashboardNumberFont}>
                          {get(purchaseOrderReport, ['partialReceived'], '--')}
                        </span>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Card size="default" title="Purchase  (tax not included)">
                  <span className={classes.dashboardNumberFont}>
                    {Number(get(purchaseReport, ['month'], 0)).toLocaleString('en-IN')}
                  </span>
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Card size="default" title="Sale (tax not included)">
                  <span className={classes.dashboardNumberFont}>
                    {Number(get(saleReport, ['month'], 0)).toLocaleString('en-IN')}
                  </span>
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24} lg={24}>
                <Card size="default" title="Material">
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={12}>
                      <Card
                        size="small"
                        title={<Tooltip title="Material in Reorder Status">Material in Reorder Status</Tooltip>}
                      >
                        <Row gutter={[8, 8]}>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Raw">
                              <span className={classes.dashboardNumberFont}>{get(materialReport, ['raw'], '--')}</span>
                            </Card>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Packing">
                              <span className={classes.dashboardNumberFont}>
                                {get(materialReport, ['packing'], '--')}
                              </span>
                            </Card>
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Printed Packaging">
                              <span className={classes.dashboardNumberFont}>
                                {get(materialReport, ['printedPackaging'], '--')}
                              </span>
                            </Card>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Consumable">
                              <span className={classes.dashboardNumberFont}>
                                {get(materialReport, ['consumable'], '--')}
                              </span>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card
                        size="small"
                        title={`Inventory Amounts - ${Number(inventoryTotal).toLocaleString('en-IN')}`}
                      >
                        <Row gutter={[8, 8]}>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Raw">
                              <span className={classes.dashboardNumberFont}>
                                {Number(get(materialReport, ['rawAmount'], 0)).toLocaleString('en-IN')}
                              </span>
                            </Card>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Packing">
                              <span className={classes.dashboardNumberFont}>
                                {Number(get(materialReport, ['packingAmount'], 0)).toLocaleString('en-IN')}
                              </span>
                            </Card>
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Printed Packaging">
                              <span className={classes.dashboardNumberFont}>
                                {Number(get(materialReport, ['printedPackagingAmount'], 0)).toLocaleString('en-IN')}
                              </span>
                            </Card>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Card size="small" title="Consumable">
                              <span className={classes.dashboardNumberFont}>
                                {Number(get(materialReport, ['consumableAmount'], 0)).toLocaleString('en-IN')}
                              </span>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={12}>
                      <Card size="small" title={exportCustomerInventoryButton()}>
                        <Table
                          columns={customersInventoryTotalTableColumns}
                          bordered
                          rowKey={record => record.name}
                          pagination={{
                            defaultPageSize: 10,
                            position: ['bottomLeft'],
                          }}
                          dataSource={customerInventoryReport}
                          size="small"
                          scroll={{ x: 425, y: 400 }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        ) : null}
      </>
    </Structure>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
